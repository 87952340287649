import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import cityProfileImage from '../../../assests/smart/1.jpg';
import cityProfileImage1 from '../../../assests/smart/2.jpg';
import cityProfileImage2 from '../../../assests/smart/3.jpg';
import cityProfileImage3 from '../../../assests/smart/4.jpg';
import cityProfileImage4 from '../../../assests/smart/5.jpg';
import cityProfileImage5 from '../../../assests/smart/6.jpg';
import cityProfileImage6 from '../../../assests/smart/7.jpg';
import cityProfileImage7 from '../../../assests/smart/8.jpg';
import cityProfileImage8 from '../../../assests/smart/9.jpg';
import cityProfileImage16 from '../../../assests/smart/12.jpg';
import cityProfileImage17 from '../../../assests/smart/13.jpg';
import cityProfileImage18 from '../../../assests/smart/14.jpg';
import cityProfileImage19 from '../../../assests/smart/15.jpg';
import cityProfileImage20 from '../../../assests/smart/16.jpg';
import cityProfileImage21 from '../../../assests/smart/17.jpg';


import cityProfileImage9 from '../../../assests/smart/hhh/1.jpg';
import cityProfileImage10 from '../../../assests/smart/hhh/2.jpg';
import cityProfileImage11 from '../../../assests/smart/hhh/3.jpg';
import cityProfileImage12 from '../../../assests/smart/hhh/4.jpg';
import cityProfileImage13 from '../../../assests/smart/hhh/5.jpg';
import cityProfileImage14 from '../../../assests/smart/hhh/6.jpg';
import cityProfileImage15 from '../../../assests/smart/hhh/7.jpg';

import { useSelector } from 'react-redux';

const photos = [
  { src: cityProfileImage, width: 4, height: 3 },
  { src: cityProfileImage1, width: 4, height: 3 },
  { src: cityProfileImage2, width: 4, height: 3 },
  { src: cityProfileImage3, width: 4, height: 3 },
  { src: cityProfileImage4, width: 4, height: 3 },
  { src: cityProfileImage5, width: 4, height: 3 },
  { src: cityProfileImage6, width: 4, height: 3 },
  { src: cityProfileImage7, width: 4, height: 3 },
  { src: cityProfileImage8, width: 4, height: 3 },

  { src: cityProfileImage16, width: 4, height: 3 },
  { src: cityProfileImage17, width: 4, height: 3 },
  { src: cityProfileImage18, width: 4, height: 3 },
  { src: cityProfileImage19, width: 4, height: 3 },
  { src: cityProfileImage20, width: 4, height: 3 },
  { src: cityProfileImage21, width: 4, height: 3 },

  { src: cityProfileImage9, width: 4, height: 3 },


  { src: cityProfileImage10, width: 4, height: 3 },
  { src: cityProfileImage11, width: 4, height: 3 },
  { src: cityProfileImage12, width: 4, height: 3 },
  { src: cityProfileImage13, width: 4, height: 3 },
  { src: cityProfileImage14, width: 4, height: 3 },
  { src: cityProfileImage15, width: 4, height: 3 },


];

function Slidersmart({ onImageSelect }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const lang = useSelector((state) => state.lang.lang);

  useEffect(() => {
    setLoaded(false);
  }, [photoIndex]);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const openLightbox = () => {
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleThumbnailClick = (index) => {
    setPhotoIndex(index);

    if (index >= 15) {
      onImageSelect(photos[index].src); 
    }
  };

  return (
    <div className="photo-gallery container">
      <div className="main-image d-flex justify-content-center">
        <img
          src={photos[photoIndex].src}
          alt="Main"
          onLoad={handleImageLoad}
          className={loaded ? 'loaded' : ''}
          onClick={openLightbox}
        />
      </div>

      <div className="section-title text-center mt-4">
      <h3 className="group-title" style={{fontFamily:'cairo'}}>{lang === 'en' ? 'Sample full project' : 'عينة مشروع كاملة'}</h3>
      </div>

      <div className="thumbnail-gallery">
        {photos.slice(0, 15).map((photo, index) => (
          <div key={index} className="thumbnail-item">
            <img
              src={photo.src}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(index)} // فقط تغيير الصورة بدون onImageSelect
              className={photoIndex === index ? 'active-thumbnail' : ''}
            />
          </div>
        ))}
      </div>
      <div className="section-title text-center mt-4">
      <h3 className="group-title" style={{fontFamily:'cairo'}}>{lang === 'en' ? 'Designs that suit your style' : ' تصميمات تناسب ذوقك '}</h3>
      </div>

      <div className="thumbnail-gallery">
        {photos.slice(15).map((photo, index) => (
          <div key={index + 15} className="thumbnail-item">
            <img
              src={photo.src}
              alt={`Thumbnail ${index + 10}`}
              onClick={() => handleThumbnailClick(index + 15)} // يسمح باختيار الصور من 10 وما فوق
              className={photoIndex === index + 15 ? 'active-thumbnail' : ''}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      )}
    </div>
  );
}

export default Slidersmart;
