import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import cityProfileImage from '../../../assests/elite/1.png';
import cityProfileImage1 from '../../../assests/elite/2.png';
import cityProfileImage2 from '../../../assests/elite/3.png';
import cityProfileImage3 from '../../../assests/elite/4.png';
import cityProfileImage4 from '../../../assests/elite/5.png';
import cityProfileImage5 from '../../../assests/elite/6.png';
import cityProfileImage6 from '../../../assests/elite/elite (4)/1.jpg';
import cityProfileImage7 from '../../../assests/elite/elite (4)/2.jpg';
import cityProfileImage8 from '../../../assests/elite/elite (4)/3.jpg';
import cityProfileImage9 from '../../../assests/elite/elite (4)/4.jpg';
import cityProfileImage10 from '../../../assests/elite/elite (4)/5.jpg';
import cityProfileImage11 from '../../../assests/elite/elite (4)/6.jpg';
import cityProfileImage12 from '../../../assests/elite/elite (4)/7.jpg';
import cityProfileImage13 from '../../../assests/elite/elite (4)/8.jpg';
import cityProfileImage14 from '../../../assests/elite/elite (4)/9.jpg';
import cityProfileImage15 from '../../../assests/elite/elite (4)/10.jpg';
import cityProfileImage16 from '../../../assests/elite/elite (4)/11.jpg';
import cityProfileImage17 from '../../../assests/elite/elite (4)/12.jpg';


import { useSelector } from 'react-redux';


const photos = [
  { src: cityProfileImage, width: 4, height: 3 },
  { src: cityProfileImage1, width: 4, height: 3 },
  { src: cityProfileImage2, width: 4, height: 3 },
  { src: cityProfileImage3, width: 4, height: 3 } , 
  { src: cityProfileImage4, width: 4, height: 3 },
  { src: cityProfileImage5, width: 4, height: 3 }, 
  { src: cityProfileImage6, width: 4, height: 3 }, 
  { src: cityProfileImage7, width: 4, height: 3 }, 
  { src: cityProfileImage8, width: 4, height: 3 }, 
  { src: cityProfileImage9, width: 4, height: 3 }, 
  { src: cityProfileImage10, width: 4, height: 3 }, 
  { src: cityProfileImage11, width: 4, height: 3 }, 
  { src: cityProfileImage12, width: 4, height: 3 }, 
  { src: cityProfileImage13, width: 4, height: 3 }, 
  { src: cityProfileImage14, width: 4, height: 3 }, 
  { src: cityProfileImage15, width: 4, height: 3 }, 
  { src: cityProfileImage16, width: 4, height: 3 }, 
  { src: cityProfileImage17, width: 4, height: 3 }, 
  
 

];


function Sliderelit({ onImageSelect }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const lang = useSelector((state) => state.lang.lang);

  useEffect(() => {
    setLoaded(false);
  }, [photoIndex]);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const openLightbox = () => {
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleThumbnailClick = (index) => {
    setPhotoIndex(index);

    if (index >= 0) {
      onImageSelect(photos[index].src); 
    }
  };

  return (
    <div className="photo-gallery container">
      <div className="main-image d-flex justify-content-center">
        <img
          src={photos[photoIndex].src}
          alt="Main"
          onLoad={handleImageLoad}
          className={loaded ? 'loaded' : ''}
          onClick={openLightbox}
        />
      </div>

      <div className="section-title text-center mt-4" style={{display:'none'}}>
      <h3 className="group-title" style={{fontFamily:'cairo'}}>{lang === 'en' ? 'Sample full project' : 'عينة مشروع كاملة'}</h3>
      </div>

      <div className="thumbnail-gallery" style={{display:'none'}}>
        {photos.slice(0, 0).map((photo, index) => (
          <div key={index} className="thumbnail-item">
            <img
              src={photo.src}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(index)}
              className={photoIndex === index ? 'active-thumbnail' : ''}
            />
          </div>
        ))}
      </div>
      <div className="section-title text-center mt-4">
      <h3 className="group-title" style={{fontFamily:'cairo'}}>{lang === 'en' ? 'Designs that suit your style' : ' تصميمات تناسب ذوقك '}</h3>
      </div>

      <div className="thumbnail-gallery">
        {photos.slice(0).map((photo, index) => (
          <div key={index + 0} className="thumbnail-item">
            <img
              src={photo.src}
              alt={`Thumbnail ${index + 10}`}
              onClick={() => handleThumbnailClick(index + 0)}
              className={photoIndex === index + 0 ? 'active-thumbnail' : ''}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      )}
    </div>
  );
}

export default Sliderelit;






