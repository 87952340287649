import React, { useState, useEffect } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import cityProfileImage from '../../../assests/modern/1.jpg';
import cityProfileImage1 from '../../../assests/modern/2.jpg';
import cityProfileImage2 from '../../../assests/modern/3.jpg';
import cityProfileImage3 from '../../../assests/modern/4.jpg';
import cityProfileImage4 from '../../../assests/modern/5.jpg';
import cityProfileImage5 from '../../../assests/modern/6.jpg';
import cityProfileImage6 from '../../../assests/modern/7.jpg';
import cityProfileImage7 from '../../../assests/modern/8.jpg';
import cityProfileImage8 from '../../../assests/modern/9.jpg';
import cityProfileImage9 from '../../../assests/modern/10.jpg';
import cityProfileImage10 from '../../../assests/modern/11.jpg';
import cityProfileImage11 from '../../../assests/modern/12.jpg';
import cityProfileImage12 from '../../../assests/modern/13.jpg';
import cityProfileImage13 from '../../../assests/modern/14.jpg';
import cityProfileImage14 from '../../../assests/modern/15.jpg';
import cityProfileImage15 from '../../../assests/modern/16.jpg';
import cityProfileImage16 from '../../../assests/modern/mod/1.jpg';
import cityProfileImage17 from '../../../assests/modern/mod/2.jpg';
import cityProfileImage18 from '../../../assests/modern/mod/3.jpg';
import cityProfileImage19 from '../../../assests/modern/mod/4.jpg';
import cityProfileImage20 from '../../../assests/modern/mod/5.jpg';
import cityProfileImage21 from '../../../assests/modern/m/1.jpg';
import cityProfileImage22 from '../../../assests/modern/m/2.jpg';
import cityProfileImage23 from '../../../assests/modern/m/3.jpg';
import cityProfileImage24 from '../../../assests/modern/m/4.jpg';
import cityProfileImage25 from '../../../assests/modern/m/5.jpg';
import cityProfileImage26 from '../../../assests/modern/m/6.jpg';
import cityProfileImage27 from '../../../assests/modern/m/7.jpg';
import cityProfileImage28 from '../../../assests/modern/m/8.jpg';

import cityProfileImage35 from '../../../assests/modern/mod/6.jpg';
import cityProfileImage36 from '../../../assests/modern/mod/7.jpg';
import cityProfileImage37 from '../../../assests/modern/mod/8.jpg';
import cityProfileImage38 from '../../../assests/modern/mod/9.jpg';
import cityProfileImage39 from '../../../assests/modern/mod/10.jpg';
import cityProfileImage40 from '../../../assests/modern/mod/11.jpg';
import cityProfileImage41 from '../../../assests/modern/mod/12.jpg';
import cityProfileImage42 from '../../../assests/modern/mod/13.jpg';
import cityProfileImage43 from '../../../assests/modern/mod/14.jpg';
import cityProfileImage44 from '../../../assests/modern/mod/15.jpg';
import cityProfileImage45 from '../../../assests/modern/mod/16.jpg';
import cityProfileImage46 from '../../../assests/modern/mod/17.jpg';
import cityProfileImage47 from '../../../assests/modern/mod/18.jpg';
import cityProfileImage48 from '../../../assests/modern/mod/19.jpg';
import cityProfileImage49 from '../../../assests/modern/mod/20.jpg';
import cityProfileImage50 from '../../../assests/modern/mod/21.jpg';
import cityProfileImage51 from '../../../assests/modern/mod/22.jpg';
import cityProfileImage52 from '../../../assests/modern/mod/23.jpg';
import cityProfileImage53 from '../../../assests/modern/mod/24.jpg';
import cityProfileImage54 from '../../../assests/modern/mod/25.jpg';
import cityProfileImage55 from '../../../assests/modern/mod/26.jpg';


import cityProfileImage29 from '../../../assests/val/1.jpg';
import cityProfileImage30 from '../../../assests/val/2.jpg';
import cityProfileImage32 from '../../../assests/val/3.jpg';
import cityProfileImage33 from '../../../assests/val/4.jpg';
import cityProfileImage34 from '../../../assests/val/5.jpg';
import { useSelector } from 'react-redux';


const photos = [
  { src: cityProfileImage, width: 4, height: 3 },
  { src: cityProfileImage1, width: 4, height: 3 },
  { src: cityProfileImage2, width: 4, height: 3 },
  { src: cityProfileImage3, width: 4, height: 3 } , 
  { src: cityProfileImage4, width: 4, height: 3 },
  { src: cityProfileImage5, width: 4, height: 3 }, 
   { src: cityProfileImage6, width: 4, height: 3 },
  { src: cityProfileImage7, width: 4, height: 3 }, 
   { src: cityProfileImage8, width: 4, height: 3 },
  { src: cityProfileImage9, width: 4, height: 3 }, 
   { src: cityProfileImage10, width: 4, height: 3 },
  { src: cityProfileImage11, width: 4, height: 3 }, 
   { src: cityProfileImage12, width: 4, height: 3 },
  { src: cityProfileImage13, width: 4, height: 3 },  
  { src: cityProfileImage14, width: 4, height: 3 },  
  { src: cityProfileImage15, width: 4, height: 3 },
  { src: cityProfileImage55, width: 2, height: 2 },



  { src: cityProfileImage29, width: 4, height: 3 },
  { src: cityProfileImage33, width: 4, height: 3 },
  { src: cityProfileImage34, width: 4, height: 3 },
  { src: cityProfileImage32, width: 4, height: 3 },
  { src: cityProfileImage30, width: 4, height: 3 },



 

  { src: cityProfileImage34, width: 2, height: 2 },
  { src: cityProfileImage35, width: 2, height: 2 },
  { src: cityProfileImage36, width: 2, height: 2 },
  { src: cityProfileImage37, width: 2, height: 2 },
  { src: cityProfileImage38, width: 2, height: 2 },
  { src: cityProfileImage39, width: 2, height: 2 },
  { src: cityProfileImage40, width: 2, height: 2 },
  { src: cityProfileImage41, width: 2, height: 2 },
  { src: cityProfileImage42, width: 2, height: 2 },
  { src: cityProfileImage43, width: 2, height: 2 },
  { src: cityProfileImage44, width: 2, height: 2 },
  { src: cityProfileImage45, width: 2, height: 2 },
  { src: cityProfileImage46, width: 2, height: 2 },
  { src: cityProfileImage47, width: 2, height: 2 },
  { src: cityProfileImage48, width: 2, height: 2 },
  { src: cityProfileImage49, width: 2, height: 2 },
  { src: cityProfileImage50, width: 2, height: 2 },
  { src: cityProfileImage51, width: 2, height: 2 },

 


  { src: cityProfileImage17, width: 4, height: 3 },
  { src: cityProfileImage18, width: 4, height: 3 },
  { src: cityProfileImage20, width: 4, height: 3 },
  { src: cityProfileImage21, width: 4, height: 3 },
  { src: cityProfileImage22, width: 4, height: 3 },
  { src: cityProfileImage23, width: 4, height: 3 },
  { src: cityProfileImage25, width: 4, height: 3 },
  { src: cityProfileImage26, width: 4, height: 3 },
  { src: cityProfileImage28, width: 4, height: 3 },
  { src: cityProfileImage52, width: 2, height: 2 },

  { src: cityProfileImage27, width: 4, height: 3 },
  { src: cityProfileImage53, width: 2, height: 2 },
  { src: cityProfileImage54, width: 2, height: 2 },
  
];


function Slider({ onImageSelect }) {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const lang = useSelector((state) => state.lang.lang);

  useEffect(() => {
    setLoaded(false);
  }, [photoIndex]);

  const handleImageLoad = () => {
    setLoaded(true);
  };

  const openLightbox = () => {
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  const handleThumbnailClick = (index) => {
    setPhotoIndex(index);
    if (index >= 16) {
      onImageSelect(photos[index].src); 
    }
  };

  return (
    <div className="photo-gallery container">
      <div className="main-image d-flex justify-content-center">
        <img
          src={photos[photoIndex].src}
          alt="Main"
          onLoad={handleImageLoad}
          className={loaded ? 'loaded' : ''}
          onClick={openLightbox}
        />
      </div>

      {/* القسم الأول */}
      <div className="section-title text-center mt-4">
        <h3 className="group-title" style={{ fontFamily: 'cairo' }}>
          {lang === 'en' ? 'Sample full project' : 'عينة مشروع كاملة'}
        </h3>
      </div>
      <div className="thumbnail-gallery">
        {photos.slice(0, 16).map((photo, index) => (
          <div key={index} className="thumbnail-item">
            <img
              src={photo.src}
              alt={`Thumbnail ${index + 1}`}
              onClick={() => handleThumbnailClick(index)}
              className={photoIndex === index ? 'active-thumbnail' : ''}
            />
          </div>
        ))}
      </div>

      {/* القسم الثاني */}
      <div className="section-title text-center mt-4">
        <h3 className="group-title" style={{ fontFamily: 'cairo' }}>
          {lang === 'en' ? 'Sample full project2' : '2عينة مشروع كاملة'}
        </h3>
      </div>
      <div className="thumbnail-gallery">
        {photos.slice(16, 21).map((photo, index) => (
          <div key={index + 16} className="thumbnail-item">
            <img
              src={photo.src}
              alt={`Thumbnail ${index + 17}`}
              onClick={() => handleThumbnailClick(index + 16)}
              className={photoIndex === index + 16 ? 'active-thumbnail' : ''}
            />
          </div>
        ))}
      </div>

      {/* القسم الثالث */}
      <div className="section-title text-center mt-4">
        <h3 className="group-title" style={{ fontFamily: 'cairo' }}>
          {lang === 'en' ? 'Designs that suit your style' : 'تصميمات تناسب ذوقك'}
        </h3>
      </div>
      <div className="thumbnail-gallery">
        {photos.slice(21).map((photo, index) => (
          <div key={index + 21} className="thumbnail-item">
            <img
              src={photo.src}
              alt={`Thumbnail ${index + 22}`}
              onClick={() => handleThumbnailClick(index + 21)}
              className={photoIndex === index + 21 ? 'active-thumbnail' : ''}
            />
          </div>
        ))}
      </div>

      {isOpen && (
        <Lightbox
          mainSrc={photos[photoIndex].src}
          nextSrc={photos[(photoIndex + 1) % photos.length].src}
          prevSrc={photos[(photoIndex + photos.length - 1) % photos.length].src}
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % photos.length)
          }
        />
      )}
    </div>
  );
}

export default Slider;
