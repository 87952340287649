import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import './sev.css';
import im from '../../assests/images/Untitled (720 x 500 px) (4).png';

const CustomComponent = () => {
  const lang = useSelector((state) => state.lang.lang);
  const containerRef = useRef(null);
  const isDarkMode = useSelector((state) => state.darkMode.darkMode);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
          entry.target.classList.remove('hidden');
        } else {
          entry.target.classList.remove('visible');
          entry.target.classList.add('hidden');
        }
      });
    }, options);

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  const footerStyle = {
    backgroundColor: isDarkMode ? 'var(--background-color-dark)' : 'var(--background-color-light)',
    color: isDarkMode ? 'var(--text-color-dark)' : 'var(--text-color-light)',
  };

  return (
    <div
      ref={containerRef}
      className="custom-container container "
      style={footerStyle}
    >
      <div className="image-container">
        <img
          src={im}
          alt="Interior Design"
          className="main-image hidden"
        />
      </div>
      <div className="text-container">
        {lang === 'en' ? (
          <>
            <p className="english-text">
            Modern Homes is your ideal partner in transforming your house into a living space that reflects your refined taste. 
            </p>
            <p className="english-text">
            We specialize in the complete renovation and professional finishing of your entire home, including bathrooms, kitchens, furniture, and decor. 
            </p>

          </>
        ) : (
          <>
            <p className="arabic-text">

             الأبيات المواكبة   هي شريكك المثالي في تحويل منزلك إلى مساحة معيشية تعكس ذوقك الراقي
            </p>
            <p className="arabic-text">
             نحن متخصصون في الترميم والتشطيب الاحترافي لكامل منزلك، بما في ذلك دورات المياه والمطابخ والأثاث والديكورات
            </p>
            
          
          </>
        )}
      </div>
    </div>
  );
};

export default CustomComponent;
